import React from "react"
import { Link } from "gatsby"
import MetaData from "../components/meta"
import Layout from "../components/Layout"
import "../styles/404.scss"

const NotFoundPage = ({ location }) => (
  <Layout>
    <MetaData title="404: Not found" location={location} />
    <div className="error-page">
      <div className="wrapper-1200">
        <div className="error-code">404 Error</div>

        <div className="error-message">
          Oops! We couldn’t find the page you were looking for.
        </div>

        <div className="error-subtitle">
          You may have mistyped the address or the page may have been moved
        </div>

        <Link to="/" className="button--primary button--with-shadow">
          TAKE ME TO THE HOMEPAGE
        </Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
